/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import "@fontsource/noto-serif-display"
import "@fontsource/raleway"
import "@fontsource/oranienbaum"
import "@fontsource/bona-nova"
import "@fontsource/cormorant"
import { changeLocale } from "gatsby-plugin-intl"

// const onClientEntry = () => {
//     if (window.location.pathname === "/") {
//         window.location.pathname = `/bg`
//         console.log("Language changed to BG")
//     }
//     console.log("onClientEntry")
//     window.navigator.language = 'bg-BG'
//     console.log(window.navigator.language)

// }

const onClientEntry = () => {
  Object.defineProperty(navigator, "language", {
    get() {
      return "bg-BG" // the language you want
    },
  })

  Object.defineProperty(navigator, "languages", {
    get() {
      return ["bg-BG", "bg"] // the language order you want
    },
  })
}

const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    if (location && location.hash) {
      window.setTimeout(() => {
        const element = document.querySelector(location.hash)
        // console.log(element)

        if (element) {
          // Scroll to the element (with an offset of 100 pixels)
          const headerHeight = 100 // Set this to your desired offset
          const topPos =
            element.getBoundingClientRect().top +
            window.pageYOffset -
            headerHeight
          window.scrollTo({ top: topPos})
        }
      }, 0)
    } else {
      window.setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
  }
}

export { onClientEntry, onRouteUpdate }

// export default onClientEntry
